<template>
  <div class="my-5 card">
    <div class="card-body">
      <div v-if="loading" class="text-center mb-5">
        <spinner />
        Please wait while the system is generating your pdf...<br />
        <span v-if="checkTime > 0">checking in {{ checkTime }} seconds...</span>
      </div>
      <div v-else>
        <div v-if="generated">
          <div class="text-center my-5">
            <div class="alert alert-success">PDF successfully generated!</div>

            <a
              :href="`${download_link}`"
              class="btn btn-success btn-sm"
              target="_blank"
              >Download PDF</a
            >
            <p class="mt-5">
              <a
                class="btn btn-warning btn-sm"
                href="javascript:void(0);"
                @click="triggerRequestNew"
                >Request New</a
              >
            </p>
          </div>
        </div>
        <div v-else class="my-3">
          <div v-if="failure" class="text-center mb-3">
            <div class="alert alert-danger">
              Unable to generate PDF, you may try again...
            </div>
          </div>
          <button
            @click="triggerScheduleDownload"
            :disabled="loading"
            :class="{ disabled: loading }"
          >
            <span>Generate Legacy Journal PDF</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "./Spinner.vue";
import axios from "axios";
export default {
  components: { Spinner },
  data() {
    return {
      loading: false,
      generated: false,
      failure: false,
      download_link: "",
      checkInterval: 1000,
      timerInterval: null,
      checkTime: 0,
    };
  },
  props: {},
  methods: {
    triggerDownload() {
      this.loading = true;
      this.$store.dispatch("downloadPdf", [
        this.$root.designs,
        ({ success, generate_url, public_link }) => {
          if (success) {
            axios
              .get(generate_url)
              .then((response) => response.data)
              .then(() => {
                setTimeout(() => {
                  this.loading = false;
                  this.generated = true;
                  this.download_link = public_link;
                  window.open(public_link, "_blank");
                }, 5000);
              })
              .catch(() => {
                setTimeout(() => {
                  this.loading = false;
                  this.generated = true;
                  this.download_link = public_link;
                  window.open(public_link, "_blank");
                }, 5000);
              });
          }
        },
      ]);
    },

    triggerCRON(callback) {
      axios.get("/wp-cron.php").then(callback);
    },
    startCheckInterval() {
      if (this.timerInterval === null) {
        this.triggerCRON(() => {
          this.checkTime = 5;
          this.timerInterval = setInterval(() => {
            this.checkTime--;
            if (this.checkTime <= 0) {
              clearInterval(this.timerInterval);
              this.checkScheduleDownload();
            }
          }, this.checkInterval);
        });
      }
    },

    triggerScheduleDownload() {
      this.loading = true;
      this.$store.dispatch("scheduleDownloadPdf", [
        this.$root.designs,
        ({ last_pdf_filename }) => {
          this.download_link = last_pdf_filename;
          this.timerInterval = null;
          this.startCheckInterval();
        },
      ]);
    },

    checkScheduleDownload() {
      this.loading = true;
      this.$store.dispatch(
        "checkScheduleDownloadPdf",
        ({ requests, last_pdf_filename }) => {
          this.loading = requests;
          this.download_link = last_pdf_filename;
          if (requests) {
            this.timerInterval = null;
            this.startCheckInterval();
          } else {
            if (this.download_link === "") {
              this.generated = false;
            } else {
              this.generated = true;
            }
          }
        }
      );
    },

    triggerRequestNew() {
      this.generated = false;
      this.loading = false;
    },
  },
  mounted() {
    this.checkScheduleDownload();
  },
};
</script>
<style scoped>
button {
  background: #364c89;
  color: #fff;
  border: none;
  box-shadow: none;
  cursor: pointer;
  display: block;
  font-size: 1.125em;
  padding: 0.667em 1.333em;
  text-align: center;
  text-decoration: none;
  overflow-wrap: break-word;
  margin: 0 auto;
}
</style>